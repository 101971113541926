import * as THREE from 'three'
import Model from './Abstracts/Model.js'
import Experience from '../Experience.js'
import Debug from '../Utils/Debug.js'
import State from "../State.js";
import Materials from "../Materials/Materials.js";
import * as SkeletonUtils from 'three/addons/utils/SkeletonUtils.js';

export default class Character extends Model {
    experience = Experience.getInstance()
    debug = Debug.getInstance()
    state = State.getInstance()
    materials = Materials.getInstance()
    scene = experience.scene
    time = experience.time
    camera = experience.camera.instance
    cameraClass = experience.camera
    renderer = experience.renderer.instance
    resources = experience.resources
    container = new THREE.Group();

    constructor() {
        super()


        this.setModel()
        this.setCamera()
        this.setAnimations()
        this.setUI()

        this.setDebug()
    }

    setModel() {
        // resource
        this.source = this.resources.items.armatureModel
        this.model = this.source.scene

        this.sharedModel = SkeletonUtils.clone( this.model );
        this.shareSkinnedMesh = {}

        this.sharedModel.traverse( ( object ) => {
            if ( object instanceof THREE.SkinnedMesh ) {
                this.shareSkinnedMesh = object;
            }
        } )
        this.sharedSkeleton = this.shareSkinnedMesh.skeleton;
        this.sharedParentBone = this.sharedModel.getObjectByName( 'mixamorigHips' );


        this.sourceHead = this.resources.items.headModel
        this.headModel = this.sourceHead.scene
        this.headModel.bindMode = THREE.DetachedBindMode;

        this.headModel.traverse( ( object ) => {
            if ( object instanceof THREE.SkinnedMesh ) {
                object.bind( this.sharedSkeleton, new THREE.Matrix4() );
            }
        } )

        this.sourceBody = this.resources.items.bodyModel
        this.bodyModel = this.sourceBody.scene
        this.bodyModel.bindMode = THREE.DetachedBindMode;

        this.bodyModel.traverse( ( object ) => {
            if ( object instanceof THREE.SkinnedMesh ) {
                object.bind( this.sharedSkeleton, new THREE.Matrix4() );
            }
        } )

        this.sourceLegs = this.resources.items.legsModel
        this.legsModel = this.sourceLegs.scene
        this.legsModel.bindMode = THREE.DetachedBindMode;

        this.legsModel.traverse( ( object ) => {
            if ( object instanceof THREE.SkinnedMesh ) {
                object.bind( this.sharedSkeleton, new THREE.Matrix4() );
            }
        } )

        this.sourceFeet = this.resources.items.feetModel
        this.feetModel = this.sourceFeet.scene
        this.feetModel.bindMode = THREE.DetachedBindMode;

        this.feetModel.traverse( ( object ) => {
            if ( object instanceof THREE.SkinnedMesh ) {
                object.bind( this.sharedSkeleton, new THREE.Matrix4() );
            }
        } )

        this.container.add( this.sharedModel, this.headModel, this.bodyModel, this.legsModel, this.feetModel );
        this.scene.add( this.container );
    }

    setCamera() {
        const box = new THREE.Box3().setFromObject( this.container );
        const centerBox = box.getCenter( new THREE.Vector3() );

        this.camera.position.setY( centerBox.y );
        this.cameraClass.controls.target = centerBox;
    }

    setAnimations() {
        this.animation = {}

        // Mixer
        this.animation.mixer = new THREE.AnimationMixer( this.sharedParentBone )

        // Actions
        this.animation.actions = {}

        this.animation.actions.dancing_1 = this.animation.mixer.clipAction( this.source.animations[ 0 ] )
        this.animation.actions.dancing_2 = this.animation.mixer.clipAction( this.source.animations[ 1 ] )
        this.animation.actions.dying = this.animation.mixer.clipAction( this.source.animations[ 2 ] )



        this.animation.actions.current = this.animation.actions.dancing_1
        //this.animation.actions.current.play()

        // Play the action
        this.animation.play = ( name ) => {
            const newAction = this.animation.actions[ name ]
            const oldAction = this.animation.actions.current

            newAction.reset()
            newAction.play()
            newAction.crossFadeFrom( oldAction, 1 )

            this.animation.actions.current = newAction
        }
    }

    setUI() {
        this.button_1 = document.getElementById( 'button_animation_1' );
        this.button_2 = document.getElementById( 'button_animation_2' );
        this.button_3 = document.getElementById( 'button_animation_3' );

        this.button_1.addEventListener( 'click', () => {
            this.animation.play( 'dancing_1' )
        } )

        this.button_2.addEventListener( 'click', () => {
            this.animation.play( 'dancing_2' )
        } )

        this.button_3.addEventListener( 'click', () => {
            this.animation.play( 'dying' )
        } )
    }

    resize() {

    }

    setDebug() {
        if ( !this.debug.active ) return

        //this.debug.createDebugTexture( this.resources.items.displacementTexture )
    }

    update( deltaTime ) {
        this.animation && this.animation.mixer.update( this.time.delta )
    }

}
