export default [
    // {
    //     name: 'backgroundSound',
    //     type: 'audio',
    //     path: '/sounds/background.mp3'
    // },
    // {
    //     name: 'characterModel',
    //     type: 'gltfModel',
    //     path: '/models/birdman_dance_123.glb'
    // },
    {
        name: 'armatureModel',
        type: 'gltfModel',
        path: '/models/test/armature.glb'
    },
    {
        name: 'headModel',
        type: 'gltfModel',
        path: '/models/test/head.glb'
    },
    {
        name: 'bodyModel',
        type: 'gltfModel',
        path: '/models/test/body.glb'
    },
    {
        name: 'legsModel',
        type: 'gltfModel',
        path: '/models/test/legs.glb'
    },
    {
        name: 'feetModel',
        type: 'gltfModel',
        path: '/models/test/feet.glb'
    },
    {
        name: 'displacementTexture',
        type: 'texture',
        path: '/textures/displacement.jpg'
    },
    {
        name: 'hdrTexture',
        type: 'rgbeTexture',
        path: '/textures/studio_small_02_1k.hdr'
    }
]
